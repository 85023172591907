import { HTML_ATTRIBUTE_BLOCKER_CONNECTED, HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_THUMBNAIL } from "../dom/htmlAttributes.js";
import { getPreviousVisibleElement } from "../utils/previousVisibleElement.js";
function isHtmlNodeContentBlocker(node) {
    return node.hasAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED);
}
function mapIgnoreInvisibleAndUsePreviousVisible(element) {
    return element.offsetParent ? element : getPreviousVisibleElement(element, isHtmlNodeContentBlocker);
}
/**
 * Find a duplicate before a given node to avoid duplicate content blockers for the same content.
 */ function findPreviousVisibleDuplicate(createBefore, node, blockerId, /**
     * If `true`, exceptions will be disabled. For example, if the blocked node has a visual thumbnail,
     * check if the blocked content of the possible duplicate has not the same visual thumbnail.
     */ disableExceptions) {
    var _createBefore_parentElement, _createBefore_parentElement_parentElement, _createBefore_parentElement1, _secondParentPreviousElementSibling_lastElementChild;
    const { previousElementSibling } = createBefore;
    const visualThumbnail = node.getAttribute(HTML_ATTRIBUTE_THUMBNAIL);
    const firstParentPreviousElementSibling = (_createBefore_parentElement = createBefore.parentElement) == null ? void 0 : _createBefore_parentElement.previousElementSibling;
    const secondParentPreviousElementSibling = (_createBefore_parentElement1 = createBefore.parentElement) == null ? void 0 : (_createBefore_parentElement_parentElement = _createBefore_parentElement1.parentElement) == null ? void 0 : _createBefore_parentElement_parentElement.previousElementSibling;
    const possibleDuplicates = [
        getPreviousVisibleElement(createBefore, isHtmlNodeContentBlocker),
        previousElementSibling,
        previousElementSibling == null ? void 0 : previousElementSibling.lastElementChild,
        // 1st parent up
        firstParentPreviousElementSibling,
        firstParentPreviousElementSibling == null ? void 0 : firstParentPreviousElementSibling.lastElementChild,
        // 2nd parent up
        secondParentPreviousElementSibling,
        secondParentPreviousElementSibling == null ? void 0 : secondParentPreviousElementSibling.lastElementChild,
        secondParentPreviousElementSibling == null ? void 0 : (_secondParentPreviousElementSibling_lastElementChild = secondParentPreviousElementSibling.lastElementChild) == null ? void 0 : _secondParentPreviousElementSibling_lastElementChild.lastElementChild
    ].filter(Boolean)// Get previous visible element if the last child element is not visible
    .map(mapIgnoreInvisibleAndUsePreviousVisible).filter(Boolean);
    for (const possibleDuplicate of possibleDuplicates){
        if (+possibleDuplicate.getAttribute(HTML_ATTRIBUTE_BLOCKER_ID) === blockerId && possibleDuplicate.hasAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED)) {
            const connected = +possibleDuplicate.getAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED);
            if (!disableExceptions) {
                // If the blocked node has a visual thumbnail, check if the blocked content of
                // the possible duplicate has not the same visual thumbnail. Otherwise, use it
                // as duplicate.
                const blockedContentOfPossibleDuplicate = document.querySelector(`[${HTML_ATTRIBUTE_BLOCKER_CONNECTED}="${connected}"]:not(.rcb-content-blocker)`);
                if (visualThumbnail && (blockedContentOfPossibleDuplicate == null ? void 0 : blockedContentOfPossibleDuplicate.hasAttribute(HTML_ATTRIBUTE_THUMBNAIL)) && blockedContentOfPossibleDuplicate.getAttribute(HTML_ATTRIBUTE_THUMBNAIL) !== visualThumbnail) {
                    return false;
                }
            }
            return possibleDuplicate;
        }
    }
    return false;
}
export { findPreviousVisibleDuplicate };
