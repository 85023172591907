import { iterateRules } from "./iterateRules.js";
async function iterateStylesheets(element, scores, property) {
    const { styleSheets } = document;
    for(const styleSheetIdx in styleSheets){
        const styleSheet = styleSheets[styleSheetIdx];
        let rules;
        try {
            rules = styleSheet.cssRules || styleSheet.rules;
        } catch (e) {
            continue;
        }
        if (!rules) {
            continue;
        }
        await iterateRules(rules, element, scores, property);
    }
}
export { iterateStylesheets };
