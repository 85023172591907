import { resolveScorePool } from "./resolveScorePool.js";
async function resolveNoneComputedStyle(element, property) {
    const scores = await resolveScorePool(element, property);
    return scores == null ? void 0 : scores[0].style;
}
export * from "./calculateInlineScore.js";
export * from "./calculateScore.js";
export * from "./iterateRules.js";
export * from "./iterateStylesheets.js";
export * from "./matchingSelector.js";
export * from "./priorityByRule.js";
export * from "./sortScores.js";
export * from "./resolveScorePool.js";
export { resolveNoneComputedStyle };
